#count-form {
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;
    max-width: 597px;
    box-shadow: inset 0 0 13px 1px rgba(83, 83, 83, 0.75);
    border-radius: 10px;
    border: 2px solid #ffffff;
    background-color: #ffffff;
    label, .total {
        font-size: 20px;
        @media (min-width: $sm) {
            font-size: 30px;
        }
        font-weight: 700;
        margin-bottom: 30px;
    }
    .total {
        margin-top: 30px;
    }
    .form-number, .number {
        color: $text-color;
        font-weight: 400;
        height: 52px;
        @media (min-width: $sm) {
            height: 80px;
        }
        border-radius: 10px;
        border: 1px solid #999999;
        background-color: rgba(153, 153, 153, 0.2);
        outline: transparent;
    }
    .form-number {
        @include appearance(none);
        padding: 0 40px;
        font-size: 18px;
        width: 100%;
        max-width: 250px;
        @media (min-width: $sm) {
            max-width: 472px;
            font-size: 24px;
        }
    }
    .number-block {
        .number {
            margin: 0 auto;
            font-size: 48px;
            width:100%;
            max-width: 80px;
            text-align: center;
            display: inline-block;
            @include appearance(none);
        }
        button {
            color: $text-color;
            font-size: 60px;
            font-weight: 400;
            background-color: transparent;
            border: none;
            display: inline-block;
            outline: transparent;
        }
    }

}
#popup-form {
    margin-top: 30px;
    .popup-tel {
        label {
            display: block;
            font-weight: 600;
            font-size: 18px;
            text-align: left;
        }
        input {
            @include appearance(none);
            color: $text-color;
            font-weight: 400;
            height: 52px;
            border-radius: 10px;
            border: 1px solid #999999;
            outline: transparent;
            width: 100%;
            padding: 0 20px;
        }
    }
}