@mixin reset-list {
    margin:0;
    padding: 0;
    list-style: none;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}
@mixin appearance ($value) {
    -webkit-appearance: $value;
    -moz-appearance: $value;
    appearance: $value;
}