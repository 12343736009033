.popup {
    z-index: 1000;
    display: none;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .main-popup {
        text-align: center;
        padding: 50px;
        box-shadow: inset 0 0 13px 1px rgba(83, 83, 83, 0.75);
        border: 2px solid #ffffff;
        background-color: #ffffff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 600px;
        width: 100%;
        h3 {
            font-size: 30px;
            font-weight: 700;
        }
    }
    .popup-content {
        position: relative;
    }
    .close-popup {
        position: absolute;
        top:-50px;
        right: -40px;
        display: inline-block;
        font-size: 36px;
        color: $text-color;
        text-decoration: none;
        cursor: pointer;
        background: transparent;
        border: none;
        outline: transparent;
    }
}