.about-block {
    padding-top: 30px;
    @media (min-width: $sm) {
        margin-top: -57px;
    }
}
.about-list {
    @include reset-list;
    justify-content: center;
    li {
        margin-bottom: 30px;
        text-align: center;
        .icon {
            margin: 0 auto;
            width: 111px;
            height: 111px;
            box-shadow: 0 3px 10px 1px rgba(46, 59, 131, 0.75), inset 0 2px 10px 1px #0a0f47;
            border-radius: 7px;
            border: 3px solid #ffffff;
            background-color: #c83e36;
            background-image: linear-gradient(to top, #0626a1 0%, #3e66ff 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: initial;
            }
        }
        p {
            margin: 28px 0 0;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
        }
    }
}

//tool-section
.tool-section {
    padding: 90px 0;
}
.tool-list {
    @include reset-list;
    margin-top: 44px;
    justify-content: center;
    li {
        margin-bottom: 30px;
        text-align: center;
        img {
            max-width: 356px;
            box-shadow: 0 3px 10px 1px rgba(46, 59, 131, 0.75);
            border-radius: 10px;
            border: 2px solid #ffffff;
            background-color: $text-color;
        }
        h3 {
            margin: 30px 0 20px 0;
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
        }
        p {
            margin: 0;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
        }
    }
}

//tool-detail
.tool-detail {
    padding:48px 0 70px 0;
    background: url(../img/background2.png) no-repeat center top / cover;
}
.tool-detail-block {
    .img-block img{
        max-width: 412px;
    }
    p {
        @media (min-width: $sm) {
            font-size: 24px;
            line-height: 36px;
        }
        span {
            font-weight: 700;
        }
    }
    .tool-detail-main {
        align-items: center;
        margin-top: 40px;
    }

    .tool-detail-list {
       list-style: none;
        li {
            display: flex;
            &:before {
                display: inline-block;
                content: '';
                width: 100%;
                max-width: 25px;
                height: 25px;
                margin-right: 23px;
                box-shadow: 0 3px 10px 1px rgba(46, 59, 131, 0.75), inset 0 2px 5px 1px #0a0f47;
                border-radius: 3px;
                border: 2px solid #ffffff;
                background-color: #c83e36;
                background-image: linear-gradient(to top, #0626a1 0%, #3e66ff 100%);
            }
            margin-bottom: 70px;
            h3 {
                margin: 0;
                font-size: 24px;
                font-weight: 700;
                line-height: 30.02px;
            }
            p {
                margin: 20px 0 0;
                text-align: left;
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
            }
        }
    }
}

//certificates-section
.certificates-section {
    padding: 70px 0 44px 0;
    .certificates-block {
        text-align: center;
        p {
            margin: 33px auto 40px auto;
            max-width: 760px;
        }
        h3 {
            font-size: 18px;
            font-weight: 700;
        }
    }
}
.certificates-list {
    @include reset-list;
    margin-top: 45px;

    li {
        width: 50%;
        padding: 15px;
        @media (min-width: $sm) {
            width: 33.33%;
        }
        @media (min-width: $md) {
            width: 25%;
        }
        @media (min-width: $lg) {
            width: 20%;
        }
        a {
            position: relative;
            display: inline-block;
            &:hover {
                &:after {
                    opacity: 1;
                }
            }
            &:after {
                @include transition(opacity 0.2s);
                opacity: 0;
                position: absolute;
                align-items: center;
                display: flex;
                justify-content: center;
                content: url(../img/ic2.png);
                top:0;
                bottom: 0;
                right: 0;
                left: 0;
                box-shadow: 0 3px 10px 1px rgba(46, 59, 131, 0.75);
                border-radius: 10px;
                border: 2px solid #ffffff;
                background-color: rgba(20, 61, 221, 0.3);
            }
        }
    }
}

//count-section
.count-section {
    padding: 70px 0;
    background: url(../img/background4.png) no-repeat center top / cover;
    .section-heading h2{
        color: #ffffff;
        max-width: 719px;
        margin: 0 auto;
    }
    .count-points {
        .point {
            background-image: linear-gradient(to top, #d2dbff 0%, #adbeff 100%);
        }
    }
    .count-block {
        p {
            color: #ffffff;
            max-width: 615px;
            margin: 36px auto;
        }
    }
}
//order-section
.order-section {
    padding: 70px 0 93px 0;
    text-align: center;
    @media (min-width: $sm) {
        text-align: left;
    }
}
.order-list {
    @include reset-list;
    margin-top: 66px;
    align-items: flex-end;
    li {
        margin-bottom: 30px;
        @media (min-width: $lg) {
            margin-bottom: 0;
        }
        img {
            width: initial;
        }
        h3 {
            margin: 30px 0;
            font-size: 36px;
            font-weight: 700;
            line-height: 30.05px;
        }
        p {
            @media (min-width: $sm) {
                max-width: 202px;
            }
            margin-bottom: 25px;
            font-size: 20px;
            font-weight: 400;
            span {
                display: block;
                font-size: 24px;
                font-weight: 700;
            }
        }
    }
}

//recommend-section
.recommend-section {
    padding: 63px 0 87px 0;
    background: url(../img/background3.png) no-repeat center top / cover;
    .recommend-block {
        .recommend-text {
            max-width: 700px;
            margin-top: 35px;
        }
    }
}
.recommend-list {
    @include reset-list;
    margin-top: 36px;
    align-items: flex-start;
    li {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        @media (min-width: $md) {
            margin-bottom: 0;
        }
        display: flex;
        flex-wrap: wrap;
        &:nth-child(2n) {
            .testimonial {
                order: 2;
                &:after {
                    display: none;
                }
                &:before {
                    content: url(../img/tr1.png);
                    width: 32px;
                    height: 24px;
                    display: block;
                    position: absolute;
                    transform: rotate(180deg);
                    top:-25px;
                    left: 16px;
                }
            }
            .testimonial-info {
                order: 1;
                margin: 0 0 47px 0;
            }
        }
    }
    .testimonial {
        position: relative;
        padding: 20px;
        font-size: 18px;
        font-weight: 400;
        box-shadow: inset 0 0 7px 1px rgba(83, 83, 83, 0.5);
        border-radius: 10px;
        border: 2px solid #ffffff;
        background-color: #ffffff;
        &:after {
            content: url(../img/tr1.png);
            width: 32px;
            height: 24px;
            display: block;
            position: absolute;
            left: 8px;
            bottom: -27px;
        }
    }
    .testimonial-info {
        margin-top: 33px;
        display: flex;
        img {
            margin-right: 13px;
            max-width: 82px;
            height: 79px;
        }
    }
    h3 {
        font-size: 18px;
        font-weight: 700;

    }
    span {
        font-size: 18px;
        font-weight: 400;
    }
}

//contacts-section
.contacts-section {
    padding: 50px 0;
    //text-align: center;
}
.contacts-main {
    .text {
        margin-top:34px;
    }
    .contacts {
        margin-top: 53px;
        p {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        span {
            font-size: 18px;
            @media (min-width: $md) {
                font-size: 24px;
            }
            font-weight: 700;
            line-height: 30.98px;
            color: $text-color;
        }
        img {
            width: initial;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            @media (min-width: $md) {
                width: 86px;
                height: 86px;
            }
            width: 66px;
            height: 66px;
            box-shadow: inset 0 0 7px 1px rgba(83, 83, 83, 0.5);
            border-radius: 7px;
            border: 2px solid #ffffff;
            background-color: #ffffff;
            margin-right: 22px;
        }
    }
}