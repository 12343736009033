.button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    box-shadow: 0 3px 10px 1px rgba(46, 59, 131, 0.75), inset 0 2px 10px 1px #0a0f47;
    border-radius: 7px;
    border: 3px solid #ffffff;
    background-color: #c83e36;
    background-image: linear-gradient(to top, #0626a1 0%, #3e66ff 100%);
    color: #ffffff;
    @include transition(opacity 0.2s);
    &:hover {
        color: #ffffff;
        text-decoration: none;
        opacity: 0.8;
    }
}
.header-button {
    max-width: 296px;
    height: 67px;
    text-shadow: 0 1px 1px rgba(105, 177, 39, 0.75);
    font-size: 22px;
    font-weight: 700;
    margin: 40px auto 0 auto;
    @media (min-width: $lg) {
        margin-top: 80px;
    }
    @media (min-width: $sm) {
        margin: 40px 0 0;
    }
}
.order-button {
    width: 176px;
    height: 52px;
    font-weight: 700;
    font-size: 18px;
    margin: 0 auto;
    outline: transparent;
    @media (min-width: $sm) {
        margin: 0;
    }
}
.counter-button {
    text-shadow: 0 1px 1px rgba(105, 177, 39, 0.75);
    color: #ffffff;
    height: 52px;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    max-width: 200px;
    @media (min-width: $sm) {
        max-width: 365px;
        height: 82px;
        font-size: 30px;
    }
    margin: 40px auto 0 auto;
    &:hover {
        opacity: 1;
    }
}
.popup-button {
    background-image: none;
    box-shadow: none;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    height: 62px;
    @media (min-width: $sm) {
        max-width: 365px;
        font-size: 23px;
        height: 72px;
    }
    margin: 40px auto 0 auto;
    &:hover {
        opacity: 1;
    }
}