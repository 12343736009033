@import "normalize/import-now";

@import 'utils/variables';
@import 'utils/mixins';

@import 'base/typography';
@import 'base/base';

@import 'components/buttons';
@import 'components/form';
@import 'components/popup';

@import 'layout/header';
@import 'layout/footer';

@import 'pages/home';