html {
    scroll-behavior: smooth;
}
body {
    font-family: $font-stack;
    color: $text-color;
}
img {
    width: 100%;
    vertical-align: middle;
}
.section-heading {
    text-align: center;
    h2 {
        margin: 0;
        font-size: 24px;
        @media (min-width: $sm) {
            font-size: 32px;
        }
        @media (min-width: $md) {
            font-size:40px;
        }
        @media (min-width: $lg) {
            font-size: 45px;
        }
        font-weight: 700;
    }
    .points {
        @include reset-list;
        margin-top: 30px;
        .point {
            display: inline-block;
            width: 17px;
            height: 16px;
            margin: 0 4px;
        }
    }
}
.text {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 0 auto;
}
.point {
    box-shadow: 0 3px 10px 1px rgba(46, 59, 131, 0.75), inset 0 2px 5px 1px #0a0f47;
    border-radius: 3px;
    border: 2px solid #ffffff;
    background-color: #c83e36;
    background-image: linear-gradient(to top, #0626a1 0%, #3e66ff 100%);
}
