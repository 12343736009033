.footer {
    text-align: center;
    background-color: #ffffff;
    background-image: linear-gradient(to right, #0626a1 0%, #3e66ff 100%);
    padding: 17px 0 19px 0;
    p {
        color: #ffffff;
        font-family: 'Raleway', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 20.01px;
        margin: 0;
        &:after {
            display: inline-block;
            content: url(../img/ic.png);
            position: relative;
            top: 2px;
            left: 3px;
        }
    }
}