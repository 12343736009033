.header {
    background: url(../img/background.jpg) no-repeat center top / cover;
    @media (min-width:$sm) {
        padding: 65px 0 110px 0;
        text-align: left;
    }
    padding: 35px 0 50px 0;
    text-align: center;
    .header-nav {
        .logo {
            display: inline-block;
            margin-bottom: 30px;
            img {
                @media (min-width: $lg) {
                    max-width: 306px;
                }
                max-width: 200px;
            }
        }
        .contacts {
            list-style: none;
            margin: 0;
            @media (min-width: $sm) {
                text-align: right;
            }
            li {
                @media (min-width: $md) {
                    display: inline-block;
                }
                span {
                    color: #ffffff;
                    font-size: 16px;
                    @media (min-width: $lg) {
                        font-size: 22px;
                    }
                    font-weight: 700;
                    text-decoration: none;
                }
                .mail {
                    @media (min-width: $md) {
                        margin-right: 17px;
                    }

                }
                .mail:before,.phone:before {
                    margin-right: 17px;

                }
                .mail:before {
                    content: url(../img/mail.png);
                }
                .phone:before {
                    content: url(../img/phone.png);
                }
            }
        }
    }
}
.header-main {
    margin-top: 33px;
    align-items: center;
    .header-info {
        order:2;
        @media (min-width:$sm) {
            order:1;
        }
        h1 {
            @media (min-width: $sm) {
                text-align: left;
                max-width: 439px;
                font-size: 34px;
            }
            color: #ffffff;
            font-size: 28px;
            @media (min-width: $md) {
                font-size: 40px;
            }
            @media (min-width: $lg) {
                font-size: 52px;
            }
            font-weight: 700;
            margin: 0;
        }
    }
    .header-img {
        order:1;
        @media (min-width: $sm) {
            order:2;
        }
        img {
            @media (min-width: $sm) {
                max-width: 320px;
            }
            @media (min-width: $lg) {
                max-width: 412px;
            }
            max-width: 200px;
        }
    }

}